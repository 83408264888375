<template>
  <div class="container size-mobile bg-element-white p-0 min-height-second" style="font-family: Montserrat">
    <!-- Loading --> 
    <v-dialog/>
    <div class="container mt-4" v-if="isLoading">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="3" />
        <content-placeholders-img/> 
        <content-placeholders-text :lines="2" />
        <content-placeholders-img/> 
        <content-placeholders-text :lines="1" />
      </content-placeholders> 
    </div>
    <!-- Jika selesai Loading -->
    <div class="container p-3 d-flex flex-column mt-3" v-else>
        <div id="overlay" v-if="getLocation">
          <span class="d-flex justify-content-center align-items-center" style="height: -webkit-fill-available;color:white">
            <div class="spinner-grow text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div> Sedang mendapatkan lokasi terkini
          </span>
        </div>
        <div class="alert alert-primary" role="alert" @click="caraAbsen()">
          <i class="fa fa-info-circle pr-2"></i><span style="font-size:12px;">Cara Absen <b>(Klik Disini)</b></span>
        </div>
        <!-- lat : {{lat}}
        lng : {{lng}} -->
        <!-- Hari tanggal section -->
        <div class="d-flex bd-highlight">
            <div class="p-2 bd-highlight">Hari</div>
            <div class="p-2 bd-highlight">:</div>
            <div class="p-2 bd-highlight flex-grow-1 "><strong>{{hari}}</strong></div>
        </div>
        <div class="d-flex bd-highlight">
            <div class="p-2 bd-highlight">Tanggal</div>
            <div class="p-2 bd-highlight">:</div>
            <div class="p-2 bd-highlight flex-grow-1 "><strong>{{tanggal}}</strong></div>
        </div>
        <!-- absen masuk section -->
        <div class="d-flex bd-highlight mt-3">
            <span class="text-4">Foto Serah Terima (Masuk)</span>
        </div>
         <!-- :class="{'disable': !dataAbsen.status_masuk} -->
        <div class="d-flex bd-highlight mt-3" @click="showModal('satpam_absen_masuk')" :class="{'disable': !dataAbsen.status_masuk}">
            <img v-bind:src="img" class="foto-absen"/>
        </div> 
        <button class="btn-absen-masuk mt-3" :class="{'disable': dataAbsen.status_masuk}" @click="absenMasuk()"><b>Absen Masuk</b></button>

        <!-- absen keluar section -->
        <div class="d-flex bd-highlight mt-3">
            <span class="text-4">Foto Serah Terima (Keluar)</span>
        </div>
        <div class="d-flex bd-highlight mt-3" @click="showModal('satpam_absen_keluar')" :class="{'disable': !dataAbsen.status_masuk}">
            <img v-bind:src="imgKeluar" class="foto-absen" />
        </div>
        <fieldset >
          <legend class="legend-input">Keterangan Absen Keluar</legend>
          <textarea name="tujuan" class="form-control" autocomplete="off" placeholder="Contoh: Pulang lebih awal karena sakit"  style="resize: none;" v-model="dataAbsen.keterangan_insidentil" :class="{'disable': !dataAbsen.status_masuk}"></textarea>
        </fieldset>
        <button class="btn-blue mt-4" :class="{'disable': !dataAbsen.status_masuk}" @click="absenKeluar()"><b>Absen Keluar</b></button> 
    </div> 
    <!-- {{validasiAbsensi}} -->
    <modal v-show="isModalVisible" @close="closeModal">
      <!-- Override Modal Header -->
      <template v-slot:header>Unggah Foto</template>
      
      <!-- Override Modal body -->
      <template v-slot:body>
        <form class="uploadFile d-flex flex-column justify-content-center"  @submit.prevent="uploadSubmit" enctype="multipart/form-data" method="post" style="position:absolute;bottom:0;width:95%;height:100%" >
            <div id="preview" class="d-flex align-items-center justify-content-center mb-3" style="height:80%;">
              <img v-if="imgUploaded" :src="imgUploaded" style="object-fit: contain;width: 100%;height: 100%;text-align:center"/>
            </div>
            <a class="btn btn-daftar" href="" style="width: auto !important;">
                <span class="" style="float:left;margin-right:-15px;margin-top:-3px"><img src="../../assets/img/cam.png" alt="Attach" style="width:25px;"></span>
                Ambil Gambar
                <input id="file" name="file" type='file' class="attach" accept="image/*" capture @change="onFileChange" required/>
            </a>
            <button v-if="imgUploaded" :disabled="isLoadingUpload" id="submit" class="btn btn-login mt-2" style="width: auto !important;">
              <span class="d-flex align-items-center justify-content-center" v-if="isLoadingUpload">
                  <div class="spinner-border text-primary mr-3 spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                Sedang Upload...
              </span>
              <span v-else>Upload</span>
              
            </button>
        </form>
      </template>
    </modal>

    <alert ref="c"/>
    <alert-error ref="ae"/>
</div>
</template>

<script> 
import modal from "../../components/Modal.vue";
import alert from "../../components/Alert.vue";
import alertError from "../../components/AlertError.vue";
import Api from "../../Api";

export default {
  name: "absensi",
  components: {
    modal,
    alert,
    alertError
  },
  data(){
      return {
          dataImage:{
            satpam_absen_masuk: '',
            satpam_absen_keluar: ''
          },
          validasiAbsensi:{
            status_masuk: 0, 
            status_keluar: 0, 
            absen_id:'',
          },
          hari:'',
          tanggal:'',
          img: require('../../assets/img/upload.png'),
          imgKeluar: require('../../assets/img/upload.png'),
          imgUploaded: '',
          file_upload: '',
          errorMessage: false,
          jenisAbsen: '', //untuk menentukan bukti foto masuk atau keluar
          isModalVisible: false,  //jika true akan muncul modal
          isLoading: false,  //jika true akan muncul modal
          isLoadingUpload: false, //Jika true disable button upload
          token: '',
          myData: '',
          form:{
            id_tempat_kerja: '',
            user_id: '',
            lat: '',
            lng: '',
          },
          formBuktiAbsen:{
            file_upload:'',
            jenis_upload: '',
            absen_id: '',
          },
          lat:'',
          lng:'',
          done: false,
          dataModal: {
            title: '',
            body: ''
          },
          getLocation:false,
          uploadActive: false
        }
  },
  mounted(){
    this.myData = JSON.parse(localStorage.getItem('cred'))
    this.token = JSON.parse(localStorage.getItem('token'))
    this.load()
  },  
  computed:{
    dataAbsen(){
      return this.validasiAbsensi
    }, 
  },
  methods:{ 
    // get absen
    async load(){
      // console.log('coba')
      this.isLoading = true
      await Api.get("satpam/absen/get-last/"+this.myData.id,{
        headers: {Authorization: 'Bearer ' + this.token}
      }).then((response)=>{
        // console.log(response)
        if(response.data.status_absen === "1"){
          
          // Validasi Jika sudah pernah absen
          this.validasiAbsensi = {
            status_masuk:  response.data.data.status_masuk, 
            status_keluar: response.data.data.status_keluar,
            absen_id: response.data.data.id,
            hari: response.data.data.display_hari,
            tanggal: response.data.data.display_tanggal,
          }  
          // Set Hari
          this.hari = response.data.data.display_hari
          this.tanggal = response.data.data.display_tanggal
          // Jika ada gambar absen Masuk
          if(response.data.data.data_attachments.satpam_absen_masuk){
            this.img = response.data.data.data_attachments.satpam_absen_masuk.file_url
            // this.imgUploaded = response.data.data.data_attachments.satpam_absen_masuk.file_url
          }
          // Jika ada gambar absen Keluar
          if(response.data.data.data_attachments.satpam_absen_keluar){
            this.imgKeluar = response.data.data.data_attachments.satpam_absen_keluar.file_url
            // this.imgUploaded = response.data.data.data_attachments.satpam_absen_keluar.file_url
          } 
        }
        // set property image absen masuk keluar
        this.dataImage = {
          satpam_absen_masuk : true,
          satpam_absen_keluar : true,
        }

        // Jika tidak belum pernah absen
        this.hari = response.data.display_hari
        this.tanggal = response.data.display_tanggal
        
      }).then(()=>{
        this.isLoading = false
      }).catch((error) =>{
        console.log(error)
        this.$refs.ae.showAlert()
        // this.showErrorDialog()
      })

      // set geolokasi
      
      this.getLocation = true
      this.$getLocation({
        enableHighAccuracy: true, //defaults to false
        timeout: Infinity, //defaults to Infinity
          // maximumAge: int //defaults to 0 
        })
        .then(coordinates => {
          this.lat = coordinates.lat
          this.lng = coordinates.lng
          this.getLocation = false    
        }).catch(()=>{
          this.getLocation = false
          this.$refs.c.showAlert('Maaf','Gagal Mendapatkan Lokasi, silahkan coba lagi')
              
        })
      
      
    },
    // Action absen masuk
    async absenMasuk(){  
      // Get geolokasi ( hanya work android)  
      // Form
      this.form = {
        user_id: this.myData.id,
        id_tempat_kerja: this.myData.id_tempat_kerja,
        lat: this.lat,
        lng: this.lng,
        // lat: '-67.00099',
        // lng: '108.88765',
      }

      try {
        // this.isLoading = true
        await Api.post("satpam/absen/masuk",this.form,{
          headers: {
            Authorization: 'Bearer ' + this.token
          }
        }).then((response)=>{
          // alert(JSON.stringify(response))
          console.log(response)
          this.validasiAbsensi = {
            status_masuk: "1", 
            absen_id: response.data.data.id,
          }
          // console.log(this.validasiAbsensi)
        });
        // if(response.data.status){
          // this.validasiAbsensi.status_masuk = 1
        // }
        // Jika login berhasil
        
      } catch (error) {
        alert(JSON.stringify(this.form))
        console.log(error)
      }
    },

    // Action absen Keluar
    async absenKeluar(){
      // Get geolokasi ( hanya work android) 
      this.$getLocation({
        enableHighAccuracy: true, //defaults to false
        timeout: Infinity, //defaults to Infinity
        // maximumAge: int //defaults to 0 
       })
      .then(coordinates => {
        // this.getCoordinate = coordinates
        
        this.lat = coordinates.lat
        this.lng = coordinates.lng
      });

      this.form = {
        absen_id: this.validasiAbsensi.absen_id,
        lat: this.lat,
        lng: this.lng,
        keterangan_insidentil: this.validasiAbsensi.keterangan_insidentil
        // lat: '-67.00099',
        // lng: '108.88765',
      }

      try {
        // this.isLoading = true
        await Api.post("satpam/absen/keluar",this.form,{
          headers: {Authorization: 'Bearer ' + this.token}
        }).then((response)=>{
          if(response.data.status !== 200){
            // console.log(response)
            console.log(response);
            // this.dataModal = {}
            this.$refs.c.showAlert('Maaf','Anda harus melengkapi Absen foto sebelum absen keluar')
            // this.showAlertAbsen()
          }else{
            console.log(response)
            this.validasiAbsensi = {
              status_keluar: '1',
              status_masuk: '1',
            } 
            this.done = true
            this.showSuccessAbsensi()
          }
          
        });
        // console.log(this.validasiAbsensi)
        // if(response.data.status){
          // this.validasiAbsensi.status_masuk = 1
        // }
        // Jika login berhasil
        
      } catch (error) {
        console.log(error)
      }
    },
    // Modal
    showModal(type) {
        // console.log(type) 
          
          
          if(type === 'satpam_absen_masuk'){
            if(this.dataImage.satpam_absen_masuk !== false){
              this.imgUploaded = this.img
            }
          }
          if(type=== 'satpam_absen_keluar'){
            if(this.dataImage.satpam_absen_keluar !== false){
              this.imgUploaded = this.imgKeluar
            }
          } 
        
        // console.log(this.data.data.data_attachments)
        // type masuk atau keluar
        
        this.jenisAbsen = type
        this.isModalVisible = true 
    },
    closeModal() {
        this.imgUploaded=  '',
        this.isModalVisible = false;
    }, 

    // Preview Image
    onFileChange(e) {
      const file = e.target.files[0]; 
      this.imgUploaded = URL.createObjectURL(file);
      this.file_upload = e.target.files[0]
      this.uploadActive = true
    },

    //MENGIRIM FILE UNTUK DI-UPLOAD
    async uploadSubmit() {
        this.isLoadingUpload = true
        let formData = new FormData();
        formData.append('file_upload', this.file_upload)
        formData.append('absen_id', this.validasiAbsensi.absen_id)
        formData.append('jenis_upload', this.jenisAbsen)
         
        await Api.post('satpam/absen/upload-bukti', formData, {
            headers: {
              Authorization: 'Bearer ' + this.token, 
              'Content-Type': 'multipart/form-data'
              
            }
        })
        .then((response) => {
            console.log(response)
            if(response.data.data.post_type === "satpam_absen_keluar"){
              this.imgKeluar = response.data.data.file_url
              // this.imgUploaded = response.data.data.file_url
            }
            if(response.data.data.post_type === "satpam_absen_masuk"){
              this.img = response.data.data.file_url
              // this.imgUploaded = response.data.data.file_url
            }
            this.dataImage = {
              satpam_absen_masuk : true,
              satpam_absen_keluar : true,
            }
            // Jika berhasil upload gambar
            this.closeModal()
            this.$refs.c.showAlert('Sukses','Foto berhasil diupload')
            this.isLoadingUpload = false
        }).catch((error) =>{
          console.log(error)
        })
    },
  
    showSuccessAbsensi () {
        this.$modal.show('dialog', {
        title: 'Berhasil Absen Keluar',
        text: 'Absen Hari ini telah selesai, Halaman absensi akan otomasi berganti menjadi hari selanjutnya',
        buttons: [
          {
            title: 'Tutup',
            handler: () => {
              this.$modal.hide('dialog')
              this.$router.push(this.$route.query.redirect || '/')
            }
          }, 
        ]
      })
    }, 
    caraAbsen(){
      this.$refs.c.showAlert('Cara Absen','1. Anda harus <b>Absen Masuk</b> terlebih dahulu<br>2. Upload foto serah terima(Masuk)<br>3. Upload foto serah terima (Keluar)<br>4. <b>Absen Keluar</b> <br>')
    }
  }
};
</script>
